var render = function () {
  var _vm$error, _vm$error$validationM, _vm$error2, _vm$error2$validation;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    staticClass: "wrapper h-100 justify-content-center"
  }, [_c('b-row', {
    attrs: {
      "align-h": "center"
    }
  }, [_c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('b-row', {
    attrs: {
      "align-h": "center"
    }
  }, [_c('img', {
    staticClass: "img",
    attrs: {
      "src": require("@/assets/logo/welcome_logo.png")
    }
  })])], 1)], 1), _c('b-row', {
    attrs: {
      "align-h": "center"
    }
  }, [_c('b-col', {
    attrs: {
      "cols": "12",
      "lg": "6"
    }
  }, [_c('b-overlay', {
    staticClass: "p-2",
    attrs: {
      "show": _vm.loading,
      "rounded": "sm"
    }
  }, [_vm.error && _vm.error.key ? _c('b-alert', {
    staticClass: "mt-4",
    attrs: {
      "show": "",
      "variant": "danger"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t(_vm.error.key)) + " ")]) : _vm._e(), _c('b-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.onSubmit.apply(null, arguments);
      }
    }
  }, [_c('form-input', {
    staticClass: "login-form",
    attrs: {
      "id": "login-email-field",
      "label": _vm.$t('login.form.email'),
      "validation-messages": (_vm$error = _vm.error) === null || _vm$error === void 0 ? void 0 : (_vm$error$validationM = _vm$error.validationMessages) === null || _vm$error$validationM === void 0 ? void 0 : _vm$error$validationM.email,
      "state": _vm.getState('email'),
      "type": "email"
    },
    scopedSlots: _vm._u([{
      key: "validation-messages",
      fn: function fn() {
        return [!_vm.$v.formData.email.required ? _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(_vm.$t('validation.required', {
          field: _vm.$t('login.form.email')
        })) + " ")]) : _vm._e(), !_vm.$v.formData.email.email ? _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(_vm.$t('validation.email.invalid')) + " ")]) : _vm._e()];
      },
      proxy: true
    }]),
    model: {
      value: _vm.formData.email,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "email", $$v);
      },
      expression: "formData.email"
    }
  }), _c('form-input', {
    staticClass: "login-form",
    attrs: {
      "id": "login-password-field",
      "label": _vm.$t('login.form.password'),
      "validation-messages": (_vm$error2 = _vm.error) === null || _vm$error2 === void 0 ? void 0 : (_vm$error2$validation = _vm$error2.validationMessages) === null || _vm$error2$validation === void 0 ? void 0 : _vm$error2$validation.password,
      "state": _vm.getState('password'),
      "type": "password"
    },
    scopedSlots: _vm._u([{
      key: "validation-messages",
      fn: function fn() {
        return [!_vm.$v.formData.password.required ? _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(_vm.$t('validation.required', {
          field: _vm.$t('login.form.password')
        })) + " ")]) : _vm._e()];
      },
      proxy: true
    }]),
    model: {
      value: _vm.formData.password,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "password", $$v);
      },
      expression: "formData.password"
    }
  }), _c('div', {
    staticClass: "mt-4"
  }, [_c('b-button', {
    staticClass: "w-100",
    attrs: {
      "type": "submit",
      "variant": "primary"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('login.button.submit')) + " ")])], 1)], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }