

























































































import FormValidationMixin from '@/mixins/FormValidationMixin';
import { LoginFormData } from '@/model/Authentication';
import { mixins } from 'vue-class-component';
import { Component, Emit, Prop } from 'vue-property-decorator';
import { Validations } from 'vuelidate-property-decorators';
import { email, required } from 'vuelidate/lib/validators';
import FormInput from '@/components/form/FormInput.vue';

@Component({
    components: {
        FormInput,
    },
})
export default class LoginForm extends mixins(FormValidationMixin) {
    @Prop({ type: Boolean, default: false }) loading!: boolean;

    formData = {
        email: '',
        password: '',
    } as LoginFormData;

    submitted = false;

    @Validations()
    validations = {
        formData: {
            email: { email, required },
            password: { required },
        },
    };

    @Emit()
    submit(): LoginFormData {
        return this.formData;
    }

    onSubmit(): void {
        this.submitted = true;

        this.$v.formData.$touch();
        if (!this.$v.$invalid) {
            this.submit();
        }
    }
}
