
























import { Component, Vue } from 'vue-property-decorator';
import LoginForm from '@/active-components/login/LoginForm.vue';
import { LoginFormData } from '@/model/Authentication';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { ApiError } from '@/model/ApiError';

@Component({
    components: {
        LoginForm,
    },
    methods: {
        ...mapActions({
            login: 'authenticationStorage/login',
        }),
        ...mapMutations({
            setError: 'authenticationStorage/setError',
        }),
    },
    computed: {
        ...mapGetters({
            loginError: 'authenticationStorage/getError',
        }),
    },
})
export default class Login extends Vue {
    loading = false;

    login!: (formData: LoginFormData) => Promise<void>;
    setError!: (error: ApiError | null) => void;

    mounted(): void {
        this.setError(null);
    }

    onSubmit(formData: LoginFormData): void {
        this.loading = true;

        this.login(formData)
            .then(() => {
                this.$router.push({ name: 'Users' });
            })
            .finally(() => {
                this.loading = false;
            });
    }
}
