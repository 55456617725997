var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-form-group', {
    attrs: {
      "id": "".concat(_vm.id, "-group"),
      "label": _vm.required ? "".concat(_vm.label, " ").concat(_vm.$t('form.required')) : _vm.label,
      "label-for": "".concat(_vm.id, "-field")
    }
  }, [_c('b-form-input', {
    staticClass: "form-input",
    attrs: {
      "id": "".concat(_vm.id, "-field"),
      "state": _vm.state,
      "type": _vm.type,
      "disabled": _vm.disabled,
      "placeholder": _vm.placeholder,
      "maxlength": _vm.maxlength
    },
    model: {
      value: _vm.formValue,
      callback: function callback($$v) {
        _vm.formValue = $$v;
      },
      expression: "formValue"
    }
  }), _vm._t("validation-messages"), _vm._l(_vm.validationArray, function (message, index) {
    return _c('b-form-invalid-feedback', {
      key: "validation-".concat(index)
    }, [_vm._v(" " + _vm._s(_vm.$t(message)) + " ")]);
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }