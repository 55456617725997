


























import { Component, ModelSync, Prop, Vue } from 'vue-property-decorator';

@Component
export default class FormInput extends Vue {
    @Prop(String) readonly id!: string;

    @Prop(String) readonly label!: string;

    @Prop(String) readonly placeholder!: string;

    @Prop({ default: 'text' }) readonly type!: string;

    @Prop(Boolean) readonly required!: boolean;

    @Prop({ default: null, type: Boolean }) readonly state!: boolean | null;

    @Prop({ default: false }) readonly disabled!: boolean;

    @Prop([String, Array]) readonly validationMessages!: string | string[];

    @Prop(Number) readonly maxlength!: number;

    @ModelSync('value', 'change', { type: String })
    readonly formValue!: boolean;

    get validationArray(): string[] {
        return typeof this.validationMessages === 'string'
            ? [this.validationMessages]
            : this.validationMessages;
    }
}
