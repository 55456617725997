var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    staticClass: "vh-100",
    attrs: {
      "fluid": ""
    }
  }, [_c('b-row', {
    staticClass: "justify-content-center h-100"
  }, [_c('b-col', {
    staticClass: "d-none d-lg-block justify-content-center h-100",
    attrs: {
      "cols": "7"
    }
  }, [_c('b-row', {
    staticClass: "h-100",
    attrs: {
      "align-h": "center",
      "align-v": "center"
    }
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/logo/welcome_logo.png")
    }
  })])], 1), _c('b-col', {
    staticClass: "justify-content-center h-100",
    attrs: {
      "cols": "12",
      "lg": "5"
    }
  }, [_c('b-row', {
    staticClass: "justify-content-center h-100"
  }, [_c('login-form', {
    attrs: {
      "loading": _vm.loading,
      "error": _vm.loginError
    },
    on: {
      "submit": _vm.onSubmit
    }
  })], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }