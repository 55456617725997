import { Component, Prop, Vue } from 'vue-property-decorator';
import { ApiError } from '@/model/ApiError';

@Component
export default class FormValidationMixin extends Vue {
    @Prop() error!: ApiError | null;

    submitted!: boolean;

    getState(name: string): boolean | null {
        return this.submitted
            ? !this.$v.formData?.[name]?.$error &&
                  !this.error?.validationMessages?.[name]
            : null;
    }

    getStateWithoutSubmit(name: string): boolean | null {
        if (
            !this.$v.formData?.[name]?.$error &&
            !this.error?.validationMessages?.[name]
        )
            return null;
        return false;
    }
}
